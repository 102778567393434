import ModelBase from './shared/model-base.js'

const OPTIONS = {
  i18nModelName: 'product',
  columns: [
    'id',
    'category',
    'name',
    'price',
    'points',
    'data',
    'is_countable',
    'is_for_admin'
  ]
}

export default class Product extends ModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }
}
